export const permissionsDash = [
  {
    label: 'Visualizar',
    value: 'READ_ONLY',
    desc: 'Permissão para visualizar apenas os Dashboards',
  },
  {
    label: 'Não Visualizar',
    value: 'CAN_NOT_SEE',
    desc: 'Não tem permissão para visualizar o ambiente de Dashboard',
  },
  {
    label: 'Criar e Editar',
    value: 'WRITE_AND_READ',
    desc: 'Permissão para criar e editar os Dashboards',
  },
  {
    label: 'Administrar',
    value: 'ADMIN',
    desc: 'Permissão para adicionar e excluir dashboards.',
  },
];
