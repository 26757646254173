import React from 'react';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { AiOutlineSetting } from 'react-icons/ai';
import * as S from './styles';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

interface MenuActiveUnitProps {
  onClickEkyte: () => void;
  onClickEdit: () => void;
  isHeadquarter: boolean;
  isAdmin: boolean;
}

export default function MenuActiveUnit({
  onClickEkyte,
  onClickEdit,
  isHeadquarter,
  isAdmin,
}: MenuActiveUnitProps) {
  return (
    <Menu
      menuButton={
        <S.MenuBtn disabled={!isAdmin && !isHeadquarter}>
          <AiOutlineSetting size={25} />
        </S.MenuBtn>
      }
      transition
    >
      {isAdmin && <MenuItem onClick={onClickEdit}>Editar informações</MenuItem>}
      {isHeadquarter && (
        <>
          <MenuItem onClick={onClickEkyte}>Habilitar Ekyte</MenuItem>
        </>
      )}
    </Menu>
  );
}
