export const permissionsDealBroker = [
  {
    label: 'Visualizar',
    value: 'READ_ONLY',
    desc: 'Permissão para visualizar apenas as deals ofertadas',
  },
  {
    label: 'Não Visualizar',
    value: 'CAN_NOT_SEE',
    desc: 'Não tem permissão para visualizar o ambiente do Deal Broker',
  },
  {
    label: 'Dar Lances',
    value: 'CAN_BID_DEAL_BROKER',
    desc: 'Permissão para visualizar e realizar lances nos deals ofertados',
  },
  {
    label: 'Administrar',
    value: 'ADMIN',
    desc: 'Permissão para comprar, depositar e sacar.',
  },
];
