export const permissionsHead = [
  {
    label: 'Não visualizar',
    value: 'CAN_NOT_SEE',
    desc: 'Não tem permissão para visualizar as unidades franqueadas e outros investidores.',
  },
  {
    label: 'Visualizar',
    value: 'READ_ONLY',
    desc: 'Permissão para visualizar todas as unidades franqueadas e outros investidores.',
  },
  {
    label: 'Visualizar e editar',
    value: 'WRITE_AND_READ',
    desc: 'Permissão para visualizar editar todas as unidades franqueadas e outros investidores.',
  },
  {
    label: 'Administrar',
    value: 'ADMIN',
    desc: 'Permissão para adicionar unidades, investidores e definir permissões.',
  },
];
