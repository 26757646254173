export const permissionsLead = [
  {
    label: 'Visualizar',
    value: 'READ_ONLY',
    desc: 'Permissão para visualizar apenas os leads ofertados',
  },
  {
    label: 'Não Visualizar',
    value: 'CAN_NOT_SEE',
    desc: 'Não tem permissão para visualizar o ambiente do Lead Broker',
  },
  {
    label: 'Dar Lances',
    value: 'CAN_BID',
    desc: 'Permissão para visualizar e realizar lances nos leads ofertados',
  },
  {
    label: 'Gerenciar valores',
    value: 'CAN_WITHDRAW',
    desc: 'Permissão para depositar e sacar valores',
  },
  {
    label: 'Administrar',
    value: 'ADMIN',
    desc: 'Permissão para dar lances, depositar e sacar.',
  },
];
